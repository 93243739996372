import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from './arrow.svg';

const maxScreenWidthToCollapse = 768;
export const minHeightToCollapse = 64;
export const minHeightDifferenceToCollapse = 20;
const togglerSize = 20;

export const Wrapper = styled.div<{
  realHeight: number;
  collapsed: boolean;
  noCollapse?: boolean;
}>`
  @media (max-width: ${maxScreenWidthToCollapse}px) {
    position: relative;
    overflow: hidden;
    transition: height 0.4s;
    height: ${p => p.collapsed ? minHeightToCollapse + togglerSize : p.realHeight + togglerSize}px;
    padding-bottom: ${togglerSize}px;
  }

  ${p => p.noCollapse && css`
    height: initial !important;
  `}
`;

export const Overlay = styled.div<{
  collapsed: boolean;
  noCollapse?: boolean;
}>`
  @media (max-width: ${maxScreenWidthToCollapse}px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    transition: opacity 0.4s;
    opacity: ${p => p.collapsed ? 1 : 0};
    background: linear-gradient(0deg,
      rgba(250,250,250,1) 0%,
      rgba(250,250,250,1) ${togglerSize}px,
      rgba(250,250,250,0) ${minHeightToCollapse}px,
      rgba(250,250,250,0) 100%
    );
  }

  ${p => p.noCollapse && css`
    display: none;
  `}
  @media (min-width: ${maxScreenWidthToCollapse + 1}px) {
    display: none;
  }
`;

export const Arrow = styled.button.attrs(p => ({
  children: <ArrowIcon/>,
}))<{
  collapsed: boolean;
  noCollapse?: boolean;
}>`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translatex(-16px);
  width: ${togglerSize}px;
  height: ${togglerSize}px;
  border: none;
  background: none;
  outline: none;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${p => p.theme.palette.gray[p.collapsed ? 300 : 200]};
    transform: rotate(${p => p.collapsed ? 90 : 270}deg);
    transition: transform 0.4s, fill 0.4s;
  }

  ${p => p.noCollapse && css`
    display: none;
  `}
  @media (min-width: ${maxScreenWidthToCollapse + 1}px) {
    display: none;
  }
`;
